<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        Profile de <strong>{{currentUser.firstName}} {{currentUser.lastName}}</strong>
      </h3>
    </header>
    <p>
      <strong>Token :</strong>
      {{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}}
    </p>
    <p>
      <strong>Matricule :</strong>
      {{currentUser.username}}
    </p>
    <p>
      <strong>Email :</strong>
      {{currentUser.email}}
    </p>
    <!-- <strong>Authorities:</strong>
    <ul>
      <li v-for="(role,index) in currentUser.roles" :key="index">{{role}}</li>
    </ul> -->
    <p>
      Pour <strong>changer de mot de passe</strong> veuillez vous déconnecter puis renouveler votre incription avec un nouveau mot de passe. Vous recevrez ensuite un email qui vous permettra de valider votre nouveau mot de passe.
    </p>
  </div>
</template>

<script>
export default {
  name: 'profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>